<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/loadSections";
import firebaseDeliveryBindOrder from "@/mixins/firebase/bindDeliveryOrder";
import getMenu from "@/mixins/getMenu";

export default {
  name: "Product",

  metaInfo: { title: "Agrega Producto!" },

  extends: View,

  mixins: [
    firebaseDeliveryBindOrder,
    getMenu,
    LoadSections([
      "layout-header-product-display",
      "layout-secondary-picture-product-display",
      "public-product-selection",
      "layout-footer-menu-product-selection",
    ]),
  ],
};
</script>
